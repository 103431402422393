// REACT CORE IMPORTS
import React, { useState } from 'react'

// COMPONENT IMPORTS
import Navbar from '../layout/Navbar'
import HomeFeed from '../layout/Home/HomeFeed'
import HomeNews from '../layout/Home/HomeNews'

const Home = () => {
  const [isChecked, setIsChecked] = useState(false)

  // FEED SWITCH LOGIC
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <div className='page-container'>
      <div className='content'>
        <Navbar
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
        />
        {!isChecked ? <HomeFeed /> : <HomeNews />}
      </div>
    </div>
  )
}

export default Home
