import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authorService from './authorService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  authors: [],
  author: {},
  authorError: false,
  authorSuccess: false,
  authorLoading: false,
  message: '',
}

// GET ALL AUTHORS
export const getAuthors = createAsyncThunk(
  'authors/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorService.getAuthors(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// GET AUTHOR BY ID
export const getAuthor = createAsyncThunk(
  'authors/get',
  async (authorId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await authorService.getAuthor(authorId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

export const authorSlice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    reset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getAuthors.pending, state => {
        state.authorLoading = true
        state.authorSuccess = false
      })
      .addCase(getAuthors.fulfilled, (state, action) => {
        state.authorLoading = false
        state.authorSuccess = true
        state.authors = action.payload
      })
      .addCase(getAuthors.rejected, (state, action) => {
        state.authorLoading = false
        state.authorError = true
        state.message = action.payload
      })
      .addCase(getAuthor.pending, state => {
        state.authorLoading = true
      })
      .addCase(getAuthor.fulfilled, (state, action) => {
        state.authorLoading = false
        state.authorSuccess = true
        state.author = action.payload
      })
      .addCase(getAuthor.rejected, (state, action) => {
        state.authorLoading = false
        state.authorError = true
        state.message = action.payload
      })
  },
})

export const { reset } = authorSlice.actions
export default authorSlice.reducer
