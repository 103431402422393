import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import countryService from './countryService'
import { handleCatchBlock } from '../utils/slices'

const initialState = {
  countries: [],
  countriesError: false,
  countriesSuccess: false,
  countriesLoading: false,
  message: '',
}

// Get all countries
export const getCountries = createAsyncThunk(
  'countries/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await countryService.getCountries(token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    countryReset: state => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCountries.pending, state => {
        state.countriesLoading = true
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countriesLoading = false
        state.countriesSuccess = true
        state.countries = action.payload
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.countriesLoading = false
        state.countriesError = true
        state.message = action.payload
      })
  },
})

export const { countryReset } = countrySlice.actions
export default countrySlice.reducer
