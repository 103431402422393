import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import userService from './userService'
import { handleCatchBlock } from '../utils/slices'

// GET USER FROM LOCALSTORAGE
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
  auth: user ? user : null,
  users: [],
  user: {},
  addedBy: {},
  updatedBy: {},
  userError: false,
  userSuccess: false,
  userLoading: false,
  message: '',
}

// LOGIN
export const login = createAsyncThunk('user/login', async (user, thunkAPI) => {
  try {
    return await userService.login(user)
  } catch (error) {
    return handleCatchBlock(error, thunkAPI, false)
  }
})

// LOGOUT
export const logout = createAction('user/logout', () => {
  userService.logout()
  return {}
})

// GET USER BY ID
export const getUser = createAsyncThunk(
  'users/get',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.getUser(userId, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

// UPDATE USER BY ID
export const updateUser = createAsyncThunk(
  'users/update',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().users.auth.credentials.refreshToken
      return await userService.updateUser(data.userId, data.userData, token)
    } catch (error) {
      return handleCatchBlock(error, thunkAPI, true)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: state => {
      state.users = []
      state.user = {}
      state.addedBy = {}
      state.updatedBy = {}
      state.userLoading = false
      state.userError = false
      state.userSuccess = false
      state.message = ''
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.userLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.auth = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
        state.auth = null
      })
      .addCase(logout.type, state => {
        state.auth = null
      })
      .addCase(getUser.pending, state => {
        state.userLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.user = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
      .addCase(updateUser.pending, state => {
        state.userLoading = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userLoading = false
        state.userSuccess = true
        state.user = action.payload
        state.auth.user = action.payload
        // eslint-disable-next-line
        const updateLS = localStorage.setItem(
          'user',
          JSON.stringify({
            user: action.payload,
            credentials: state.auth.credentials,
          })
        )
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userLoading = false
        state.userError = true
        state.message = action.payload
      })
  },
})

export const { reset } = userSlice.actions
export default userSlice.reducer
