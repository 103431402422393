// REACT CORE IMPORTS
import React from 'react'

// COMPONENT IMPORTS
import HomeLeft from './HomeLeft'
import HomeMain from './HomeMain'
import HomeRight from './HomeRight'

const HomeFeed = () => {
  return (
    <section className='home-feed'>
      <HomeLeft />
      <HomeMain />
      <HomeRight />
    </section>
  )
}

export default HomeFeed
