// REACT CORE IMPORTS
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

// REDUX IMPORTS
import { login, reset } from '../redux/users/userSlice'

// COMPONENT IMPORTS
import Loader from '../components/Loader'

// RESOURCE IMPORTS
import logo from '../resources/imgs/comicshub.png'

const Login = () => {
  // HOOKS
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // LOCAL STATES
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // REDUX STATES
  const { auth, userLoading, userError, userSuccess, message } = useSelector(
    state => state.users
  )

  // LOGIN LOGIC
  useEffect(() => {
    if (userError) {
      toast.error(message)
    }

    if (userSuccess || auth) {
      navigate('/home')
    }

    dispatch(reset())
  }, [userError, userSuccess, auth, message, navigate, dispatch])

  // LOGIN FUNCTION
  const onSubmit = e => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (userLoading) {
    return <Loader />
  }

  // PAGE CONTENT RETURN //

  return (
    <section className='login'>
      <form className='login-box' onSubmit={onSubmit}>
        <img src={logo} alt='comicshub logo' className='login-box-logo' />
        <div className='login-box-title'>comicshub</div>
        <input
          type='email'
          id='email'
          name='email'
          placeholder='email'
          className='login-box-input'
          required
          autoComplete='off'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <input
          type='password'
          id='password'
          name='password'
          placeholder='password'
          className='login-box-input'
          required
          autoComplete='off'
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <button className='login-box-btn'>Login</button>
      </form>
    </section>
  )
}

export default Login
