// REACT CORE IMPORTS
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

// REDUX IMPORTS
import { getAuthors } from '../../redux/authors/authorSlice'

// COMPONENT IMPORT
import Navbar from '../../layout/Navbar'

// ICON IMPORTS
import { PiPlusCircleFill as Plus } from 'react-icons/pi'

const AuthorsTable = () => {
  // HOOKS
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // REDUX STATES
  const { authors, authorLoading } = useSelector(state => state.authors)

  // GET DATA LOGIC
  useEffect(() => {
    dispatch(getAuthors())
  }, [dispatch])

  // PAGE CONTENT RETURN //

  return (
    <div className='page-container'>
      <div className='content'>
        <Navbar />
        <SkeletonTheme
          baseColor='#353535'
          highlightColor='#484848'
          duration={2}
        >
          <div className='authors'>
            <div className='authors-box'>
              <div className='authors-box-heading'>Authors</div>
              <div className='authors-box-content'>
                {authors.map(author =>
                  authorLoading ? (
                    <Skeleton width={200} height={220} borderRadius={12} />
                  ) : (
                    <div
                      className='authors-box-item'
                      onClick={() => navigate(`/authors/${author.id}`)}
                      key={author.id}
                    >
                      <div className='authors-box-item-imgbox'>
                        {!author.imageUrl || author.imageUrl === '' ? (
                          <div className='authors-box-item-non'></div>
                        ) : (
                          <img
                            src={author.imageUrl}
                            alt={`${author.firstName} ${author.lastName} `}
                            className='authors-box-item-img'
                          />
                        )}
                        <Plus className='authors-box-item-btn' />
                      </div>

                      <div className='authors-box-item-name'>{`${author.firstName} ${author.lastName} `}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  )
}

export default AuthorsTable
