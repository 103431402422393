// REACT CORE IMPORTS
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Flags from 'country-flag-icons/react/1x1'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CSSTransition as Transition } from 'react-transition-group'

// REDUX IMPORTS
import { getAuthor } from '../../redux/authors/authorSlice'
import { getCountries } from '../../redux/countries/countrySlice'

// COMPONENT IMPORTS
import Navbar from '../../layout/Navbar'

// ICON IMPORTS
import { PiPlusCircleFill as Plus } from 'react-icons/pi'

// RESOURCE IMPORTS
import dd from '../../resources/imgs/ddomni.jpg'
import sm from '../../resources/imgs/smomni.jpg'
import avatar from '../../resources/imgs/non.png'

const AuthorsDetail = () => {
  // HOOKS
  const dispatch = useDispatch()
  const params = useParams()

  const { authorId } = params

  // REDUX STATES
  const { author, authorLoading } = useSelector(state => state.authors)
  const { countries } = useSelector(state => state.countries)

  // GET DATA LOGIC
  useEffect(() => {
    dispatch(getAuthor(authorId))
    dispatch(getCountries())
  }, [dispatch, authorId])

  const birthday = new Date(author.birthday).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const authorCountry = countries?.find(
    country => country.id === author.countryId
  )

  const countryCode = authorCountry ? authorCountry.countryCode : 'US'
  const Flag = Flags[countryCode]

  // TRANSITION SETTINGS
  const transitionSettings = {
    in: !authorLoading,
    timeout: 600,
    classNames: 'fade',
  }

  // PAGE CONTENT RETURN //

  return (
    <div className='page-container'>
      <div className='content'>
        <Navbar />
        <SkeletonTheme
          baseColor='#353535'
          highlightColor='#484848'
          duration={2}
        >
          <div className='author'>
            <div className='author-left'></div>
            <div className='author-main'>
              <div className='author-main-info-box'>
                <div className='author-main-info-l'>
                  <div className='author-main-info-l-separator'></div>
                  <div className='author-main-info-l-box'>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-name'>
                        {authorLoading ? (
                          <Skeleton width={250} />
                        ) : (
                          `${author.firstName} ${author.lastName}`
                        )}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-title'>
                        {authorLoading ? <Skeleton width={100} /> : 'Full name'}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-value'>
                        {authorLoading ? (
                          <Skeleton width={250} />
                        ) : !author.fullName || author.fullName === '' ? (
                          `${author.firstName} ${author.lastName}`
                        ) : (
                          author.fullName
                        )}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-title'>
                        {authorLoading ? (
                          <Skeleton width={100} />
                        ) : author.altNames && author.altNames.length > 1 ? (
                          'Pseudonyms'
                        ) : (
                          'Pseudonym'
                        )}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-value'>
                        {authorLoading ? (
                          <Skeleton width={250} />
                        ) : author.altNames && author.altNames.length > 0 ? (
                          author.altNames.join(', ')
                        ) : (
                          'No pseudonyms'
                        )}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-title'>
                        {authorLoading ? <Skeleton width={100} /> : 'Birthday'}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-l-value'>
                        {authorLoading ? <Skeleton /> : birthday}
                      </div>
                    </Transition>
                  </div>
                </div>
                <div className='author-main-img-box'>
                  <Transition {...transitionSettings}>
                    {authorLoading ? (
                      <Skeleton circle={true} height={220} width={220} />
                    ) : (
                      <img
                        src={
                          !author.imageUrl || author.imageUrl === ''
                            ? avatar
                            : author.imageUrl
                        }
                        alt={`${author.firstName} ${author.lastName}`}
                        className='author-main-img'
                      />
                    )}
                  </Transition>
                  <Transition {...transitionSettings}>
                    <div className='author-main-nationality'>
                      <div className='author-main-nationality-img'>
                        {authorLoading ? (
                          <Skeleton circle={true} height={35} width={35} />
                        ) : (
                          <Flag />
                        )}
                      </div>
                    </div>
                  </Transition>
                  <Transition {...transitionSettings}>
                    <div className='author-main-btn'>
                      {authorLoading ? (
                        <Skeleton circle={true} height={35} width={35} />
                      ) : (
                        <Plus className='author-main-btn-icon' />
                      )}
                    </div>
                  </Transition>
                </div>
                <div className='author-main-info-r'>
                  <div className='author-main-info-r-box'>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-r-title'>
                        {authorLoading ? <Skeleton width={60} /> : 'Tags'}
                      </div>
                    </Transition>
                    <Transition {...transitionSettings}>
                      <div className='author-main-info-r-value-box'>
                        {authorLoading ? (
                          <Skeleton
                            width={250}
                            height={20}
                            count={3}
                            style={{ marginBottom: '8px' }}
                          />
                        ) : author.tags && author.tags.length > 0 ? (
                          author.tags.map((tag, index) => (
                            <div
                              className='author-main-info-r-value'
                              key={index}
                            >
                              #{tag.name}
                            </div>
                          ))
                        ) : (
                          <div>No tags available</div>
                        )}
                      </div>
                    </Transition>
                  </div>
                  <div className='author-main-info-r-separator'></div>
                </div>
              </div>
              <div className='author-main-description'>
                <Transition {...transitionSettings}>
                  <div className='author-main-description-heading'>
                    {authorLoading ? <Skeleton width={180} /> : 'Biography'}
                  </div>
                </Transition>
                <Transition {...transitionSettings}>
                  <div className='author-main-description-text'>
                    {authorLoading ? (
                      <Skeleton count={18} />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: author.description }}
                      />
                    )}
                  </div>
                </Transition>
              </div>
              <div className='author-main-description'>
                <Transition {...transitionSettings}>
                  <div className='author-main-description-heading'>
                    {authorLoading ? <Skeleton width={180} /> : `Author's work`}
                  </div>
                </Transition>
              </div>
            </div>
            <div className='author-right'>
              <Transition {...transitionSettings}>
                <div className='author-right-title'>
                  {authorLoading ? <Skeleton width={150} /> : 'Suggestions'}
                </div>
              </Transition>
              <Transition {...transitionSettings}>
                <div className='author-right-item'>
                  {authorLoading ? (
                    <Skeleton width={55} height={80} />
                  ) : (
                    <img
                      src={dd}
                      alt='cover'
                      className='author-right-item-cover'
                    />
                  )}
                  <div className='author-right-item-detail'>
                    <div className='author-right-item-detail-title'>
                      {authorLoading ? (
                        <Skeleton />
                      ) : (
                        'Daredevil Omnibus Vol. 1'
                      )}
                    </div>
                    <div className='author-right-item-detail-authors'>
                      {authorLoading ? <Skeleton /> : 'Stan Lee, Bill Everett'}
                    </div>
                    <div className='author-right-item-detail-percentage'>
                      {authorLoading ? <Skeleton width={30} /> : '92%'}
                    </div>
                    <div className='author-right-item-detail-seller'>
                      <div className='author-right-item-detail-seller-name'>
                        {authorLoading ? (
                          <Skeleton width={100} />
                        ) : (
                          'burginho23'
                        )}
                      </div>
                      <div className='author-right-item-detail-seller-flag'>
                        {authorLoading ? '' : getUnicodeFlagIcon('CA')}
                      </div>
                      <div className='author-right-item-detail-seller-price'>
                        {authorLoading ? <Skeleton width={50} /> : '$160'}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <Transition {...transitionSettings}>
                <div className='author-right-item'>
                  {authorLoading ? (
                    <Skeleton width={55} height={80} />
                  ) : (
                    <img
                      src={sm}
                      alt='cover'
                      className='author-right-item-cover'
                    />
                  )}
                  <div className='author-right-item-detail'>
                    <div className='author-right-item-detail-title'>
                      {authorLoading ? (
                        <Skeleton />
                      ) : (
                        'Spider-Man Omnibus Vol. 3'
                      )}
                    </div>
                    <div className='author-right-item-detail-authors'>
                      {authorLoading ? <Skeleton /> : 'Stan Lee, Steve Ditko'}
                    </div>
                    <div className='author-right-item-detail-percentage'>
                      {' '}
                      {authorLoading ? <Skeleton width={30} /> : '94%'}
                    </div>
                    <div className='author-right-item-detail-seller'>
                      <div className='author-right-item-detail-seller-name'>
                        {authorLoading ? <Skeleton width={100} /> : 'ahmad19'}
                      </div>
                      <div className='author-right-item-detail-seller-flag'>
                        {authorLoading ? '' : getUnicodeFlagIcon('AF')}
                      </div>
                      <div className='author-right-item-detail-seller-price'>
                        {authorLoading ? <Skeleton width={50} /> : '$180'}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  )
}

export default AuthorsDetail
