import userService from '../users/userService'

export const handleCatchBlock = (error, thunkAPI, checkAuth) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()

  if (checkAuth && message === 'Requires authentication') {
    userService.logout()
  }

  return thunkAPI.rejectWithValue(message)
}
