// REACT CORE IMPORTS
import React from 'react'

// RESOURCE IMPORTS
import logo from '../resources/imgs/comicshub.png'

const Loader = () => {
  return (
    <div className='loader-land'>
      <div className='loader'></div>
      <img src={logo} alt='comicshub logo' className='loader-img' />
    </div>
  )
}

export default Loader
