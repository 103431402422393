// REACT CORE IMPORTS
import React from 'react'

const HomeNews = () => {
  return (
    <section className='home-news'>
      <div className='home-news-grid'>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
        <div className='home-news-grid-item'></div>
      </div>
    </section>
  )
}

export default HomeNews
