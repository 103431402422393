// REACT CORE IMPORTS
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// COMPONENT IMPORTS
import Home from './pages/Home'
import Login from './pages/Login'
import AuthorsTable from './pages/tables/AuthorsTable'
import AuthorsDetail from './pages/details/AuthorsDetail'
import PrivateRoute from './components/PrivateRoute'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/home' element={<PrivateRoute />}>
            <Route path='/home' element={<Home />} />
          </Route>
          <Route path='/authors' element={<PrivateRoute />}>
            <Route path='/authors' element={<AuthorsTable />} />
          </Route>
          <Route path='/authors/:authorId' element={<PrivateRoute />}>
            <Route path='/authors/:authorId' element={<AuthorsDetail />} />
          </Route>
          <Route path='*' element={<Login />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
