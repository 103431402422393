// REDUX TOOLKIT IMPORTS
import { configureStore } from '@reduxjs/toolkit'

// REDUCER IMPORTS
import userReducer from '../redux/users/userSlice'
import authorReducer from '../redux/authors/authorSlice'
import countryReducer from '../redux/countries/countrySlice'

export const store = configureStore({
  reducer: {
    users: userReducer,
    authors: authorReducer,
    countries: countryReducer,
  },
})
