// REACT CORE IMPORTS
import React from 'react'

const HomeMain = () => {
  return (
    <div className='home-feed-main'>
      <div className='home-feed-main-stories'></div>
      <div className='home-feed-main-add-content'></div>
      <div className='home-feed-main-feed'></div>s
    </div>
  )
}

export default HomeMain
