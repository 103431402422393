// REACT CORE IMPORTS
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

// REDUX IMPORTS
import { logout } from '../redux/users/userSlice'
import { countryReset } from '../redux/countries/countrySlice'

// RESOURCE IMPORTS
import logo from '../resources/imgs/comicshub.png'
import avatar from '../resources/imgs/profile.png'

// ICON IMPORTS
import { ReactComponent as HomeIcon } from '../resources/imgs/svg/home.svg'
import { ReactComponent as DBIcon } from '../resources/imgs/svg/database.svg'
import { ReactComponent as MarketplaceIcon } from '../resources/imgs/svg/marketplace.svg'
import { ReactComponent as MessageIcon } from '../resources/imgs/svg/message.svg'
import { ReactComponent as NotificationIcon } from '../resources/imgs/svg/notification.svg'
import { ReactComponent as HeartIcon } from '../resources/imgs/svg/heart.svg'
import { ReactComponent as FeedIcon } from '../resources/imgs/svg/feed.svg'
import { ReactComponent as NewsIcon } from '../resources/imgs/svg/news.svg'
import { MdOutlineSearch } from 'react-icons/md'

const Navbar = ({ isChecked, handleCheckboxChange }) => {
  // HOOKS
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // LOCAL STATES
  const [profileMenuVisible, setProfileMenuVisible] = useState(false)
  const profileMenuRef = useRef(null)
  const currentLocation = location.pathname

  // REDUX STATES
  const { user } = useSelector(state => state.users.auth)

  // USER PROFILE LOGIC
  const toggleProfileMenu = () => {
    setProfileMenuVisible(!profileMenuVisible)
  }

  const closeProfileMenu = () => {
    setProfileMenuVisible(false)
  }

  const handleClickInsideMenu = event => {
    event.stopPropagation()
  }

  const handleClickOutsideMenu = event => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setProfileMenuVisible(false)
    }
  }

  useEffect(() => {
    if (profileMenuVisible) {
      document.addEventListener('click', handleClickOutsideMenu)
    }

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu)
    }
  }, [profileMenuVisible])

  // LOGOUT LOGIC
  const onLogout = () => {
    dispatch(logout())
    dispatch(countryReset())
    navigate('/')
  }

  // PAGE CONTENT RETURN //

  return (
    <div className='navbar'>
      <div className='navbar-left'>
        <img src={logo} alt='comicshub logo' className='navbar-left-logo' />

        <input className='navbar-left-search' placeholder='# Explore' />
        <MdOutlineSearch className='navbar-left-search-icon' />
      </div>
      <div className='navbar-main'>
        <div className='navbar-main-menu'>
          <div
            className={
              currentLocation === '/home'
                ? 'navbar-main-menu-item navbar-main-menu-item-active'
                : 'navbar-main-menu-item'
            }
            onClick={() => navigate('/home')}
          >
            <HomeIcon
              className={
                currentLocation === '/home'
                  ? 'navbar-main-menu-item navbar-main-menu-item-active'
                  : 'navbar-main-menu-item'
              }
            />
          </div>
          <div
            className={
              currentLocation.includes('/authors')
                ? 'navbar-main-menu-item navbar-main-menu-item-active'
                : 'navbar-main-menu-item'
            }
            onClick={() => navigate('/authors')}
          >
            <DBIcon
              className={
                currentLocation.includes('/authors')
                  ? 'navbar-main-menu-item navbar-main-menu-item-active'
                  : 'navbar-main-menu-item'
              }
            />
          </div>
          <div className='navbar-main-menu-item'>
            <MarketplaceIcon className='navbar-main-menu-item' />
          </div>
          <div className='navbar-main-menu-item'>
            <MessageIcon className='navbar-main-menu-item' />
          </div>
          <div className='navbar-main-menu-item navbar-main-menu-item-notification'>
            <NotificationIcon className='navbar-main-menu-item navbar-main-menu-item-notification' />
          </div>
          <div className='navbar-main-menu-item'>
            <HeartIcon className='navbar-main-menu-item' />
          </div>
        </div>
      </div>
      <div className='navbar-right'>
        <label
          className={
            currentLocation === '/home'
              ? 'navbar-right-switch navbar-right-switch-visible'
              : 'navbar-right-switch'
          }
        >
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span className='navbar-right-switch-slider'>
            <FeedIcon className='navbar-right-switch-icon navbar-right-switch-icon-1' />
            <NewsIcon className='navbar-right-switch-icon navbar-right-switch-icon-2' />
          </span>
        </label>
        <div
          className={
            profileMenuVisible
              ? 'navbar-right-profile navbar-right-profile-active'
              : 'navbar-right-profile navbar-right-profile-inactive'
          }
          onClick={toggleProfileMenu}
          onBlur={closeProfileMenu}
          ref={profileMenuRef}
        >
          <img src={avatar} alt='Avatar' className='navbar-right-profile-img' />
          {profileMenuVisible && (
            <div
              className={`navbar-right-profile-menu ${
                profileMenuVisible ? 'menu-open' : 'menu-closed'
              }`}
              onClick={handleClickInsideMenu}
            >
              <img
                src={avatar}
                alt='user avatar'
                className='navbar-right-profile-menu-img'
              />
              <div className='navbar-right-profile-menu-name'>{`${user.name} ${user.surname}`}</div>
              <button
                type='button'
                className='navbar-right-profile-menu-btn'
                onClick={onLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar
